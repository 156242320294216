"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FakeCurrentMonth = exports.CurrentMonth = exports.NextMonth = exports.PrevMonth = exports.MonthPicker = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  font-weight: 500;\n  animation: ", ";\n  visibility: ", ";\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  font-weight: 500;\n  animation: ", ";\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  border-radius: 50%;\n  border: 0;\n  color: inherit;\n  background-color: ", ";\n  box-sizing: border-box;\n  outline: 0;\n  transition: all 0.25s ease;\n  padding: 0;\n  font-size: 1em;\n  height: 60px;\n  width: 60px;\n\n  :hover {\n    cursor: pointer;\n    color: ", ";\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: auto 1fr auto;\n  grid-template-rows: 1fr;\n  grid-gap: 0;\n  align-items: center;\n  padding: 0 0.5em;\n  font-size: 1.2em;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  ", " ", "s ease-out ", "s normal both;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    opacity: 1;\n    transform: translateX(0);\n  }\n  100% {\n    opacity: 0;\n    transform: translateX(-25px);\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    opacity: 0;\n    transform: translateX(25px);\n  }\n  100% {\n    opacity: 1;\n    transform: translateX(0);\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    opacity: 1;\n    transform: translateX(0);\n  }\n  100% {\n    opacity: 0;\n    transform: translateX(25px);\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    opacity: 0;\n    transform: translateX(-25px);\n  }\n  100% {\n    opacity: 1;\n    transform: translateX(0);\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var prev = (0, _styledComponents.keyframes)(_templateObject());
var fakePrev = (0, _styledComponents.keyframes)(_templateObject2());
var next = (0, _styledComponents.keyframes)(_templateObject3());
var fakeNext = (0, _styledComponents.keyframes)(_templateObject4());
var ANIM_TIME_SEC = 0.2;
var ANIM_DELAY_TIME_SEC = 0.1;

var _makeAnimation = function _makeAnimation(type) {
  return (0, _styledComponents.css)(_templateObject5(), type, ANIM_TIME_SEC, ANIM_DELAY_TIME_SEC);
};

var animation = function animation(props) {
  var animation = props.animation;

  if (animation === 'prev') {
    return _makeAnimation(prev);
  }

  if (animation === 'next') {
    return _makeAnimation(next);
  }
};

var fakeAnimation = function fakeAnimation(props) {
  var animation = props.animation;

  if (animation === 'prev') {
    return _makeAnimation(fakePrev);
  }

  if (animation === 'next') {
    return _makeAnimation(fakeNext);
  }
};

var MonthPicker = _styledComponents["default"].div(_templateObject6());

exports.MonthPicker = MonthPicker;

var Button = _styledComponents["default"].button(_templateObject7(), function (props) {
  return props.theme.secondary;
}, function (props) {
  return props.theme.primary;
});

var PrevMonth = (0, _styledComponents["default"])(Button)(_templateObject8());
exports.PrevMonth = PrevMonth;
var NextMonth = (0, _styledComponents["default"])(Button)(_templateObject9());
exports.NextMonth = NextMonth;

var CurrentMonth = _styledComponents["default"].div(_templateObject10(), animation);

exports.CurrentMonth = CurrentMonth;

var FakeCurrentMonth = _styledComponents["default"].div(_templateObject11(), fakeAnimation, function (props) {
  return props.animation ? 'visible' : 'hidden';
});

exports.FakeCurrentMonth = FakeCurrentMonth;