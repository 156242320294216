"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PopupClose = exports.PopupHeader = exports.Popup = exports.PopupWrapper = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  margin: 0;\n  padding: 0;\n  border: 0;\n  background: none;\n  font-size: 1em;\n  color: ", ";\n  text-decoration: underline;\n\n  :hover {\n    cursor: pointer;\n  }\n\n  :disabled {\n    cursor: not-allowed;\n    color: ", ";\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  padding: 1em 0;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  margin: auto;\n  width: 100%;\n  height: 100%;\n  z-index: 3;\n  background-color: ", ";\n  font-size: 1.1em;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  text-align: center;\n  width: 420px;\n  margin: auto;\n\n  @media (max-width: 500px) {\n    width: 100%;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var PopupWrapper = _styledComponents["default"].div(_templateObject());

exports.PopupWrapper = PopupWrapper;

var Popup = _styledComponents["default"].div(_templateObject2(), function (props) {
  return props.theme.background;
});

exports.Popup = Popup;

var PopupHeader = _styledComponents["default"].header(_templateObject3());

exports.PopupHeader = PopupHeader;

var PopupClose = _styledComponents["default"].button(_templateObject4(), function (props) {
  return props.theme.primary;
}, function (props) {
  return props.theme.buttons.disabled.color;
});

exports.PopupClose = PopupClose;