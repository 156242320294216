"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FakeCalendar = exports.Calendar = void 0;

var _styledComponents = _interopRequireWildcard(require("styled-components"));

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj["default"] = obj; return newObj; } }

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  animation: ", ";\n  opacity: ", ";\n  z-index: 0;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  animation: ", ";\n  z-index: 1;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  ", " ", "s ease-in-out normal both;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    transform: translateX(0);\n  }\n  100% {\n    transform: translateX(-110%);\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    transform: translateX(110%);\n  }\n  100% {\n    transform: translateX(0);\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    transform: translateX(0);\n  }\n  100% {\n    transform: translateX(110%);\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    transform: translateX(-110%);\n  }\n  100% {\n    transform: translateX(0);\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var prev = (0, _styledComponents.keyframes)(_templateObject());
var fakePrev = (0, _styledComponents.keyframes)(_templateObject2());
var next = (0, _styledComponents.keyframes)(_templateObject3());
var fakeNext = (0, _styledComponents.keyframes)(_templateObject4());
var ANIM_TIME_SEC = 0.4;

var _makeAnimation = function _makeAnimation(type) {
  return (0, _styledComponents.css)(_templateObject5(), type, ANIM_TIME_SEC);
};

var animation = function animation(props) {
  var animation = props.animation;

  if (animation === 'prev') {
    return _makeAnimation(prev);
  }

  if (animation === 'next') {
    return _makeAnimation(next);
  }
};

var fakeAnimation = function fakeAnimation(props) {
  var animation = props.animation;

  if (animation === 'prev') {
    return _makeAnimation(fakePrev);
  }

  if (animation === 'next') {
    return _makeAnimation(fakeNext);
  }
};

var Calendar = _styledComponents["default"].div(_templateObject6(), animation);

exports.Calendar = Calendar;

var FakeCalendar = _styledComponents["default"].div(_templateObject7(), fakeAnimation, function (props) {
  return props.animation ? 1 : 0;
});

exports.FakeCalendar = FakeCalendar;