"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Wrapper = exports.DaysOfMonth = exports.DaysOfWeek = exports.MonthYear = exports.Grid = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  overflow: hidden;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: days-of-month;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  grid-area: days-of-week;\n  margin: 1em 0;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  grid-area: month-year;\n  margin: 1em 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-template-rows: auto auto 1fr;\n  grid-template-areas:\n    'month-year'\n    'days-of-week'\n    'days-of-month';\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var Grid = _styledComponents["default"].div(_templateObject());

exports.Grid = Grid;

var MonthYear = _styledComponents["default"].header(_templateObject2());

exports.MonthYear = MonthYear;

var DaysOfWeek = _styledComponents["default"].section(_templateObject3());

exports.DaysOfWeek = DaysOfWeek;

var DaysOfMonth = _styledComponents["default"].section(_templateObject4());

exports.DaysOfMonth = DaysOfMonth;

var Wrapper = _styledComponents["default"].div(_templateObject5());

exports.Wrapper = Wrapper;